import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { pageIntroFadeAnimation, progressOnScroll, setOn } from "../helpers";
import {
  Layout,
  Content,
  Newsletter,
  HTMLContent,
  ProgressNavbar,
  SectionWrapper,
  SocialIcons,
  PreviewCompatibleImage,
} from "../components";
import "../styles/_page.scss";

export const ArtistPageTemplate = ({
  content,
  contentComponent,
  tags,
  stageName,
  compatibleImage,
  social,
  recapLine,
  imgYPosition,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  const initialNavItems: any = {
    main: {
      id: "main",
      text: stageName,
      ref: React.useRef<HTMLDivElement>(null),
    },
  };
  const [scrollNavData, setScrollNavData] = React.useState(initialNavItems);

  const scrollEvent = (): void => {
    progressOnScroll(scrollNavData, setScrollNavData);
  };

  React.useEffect(() => {
    pageIntroFadeAnimation();
    setOn("main", scrollNavData, setScrollNavData);
    window.addEventListener("scroll", scrollEvent);

    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  return (
    <div className="page" ref={initialNavItems.main.ref}>
      {helmet || ""}
      <ProgressNavbar data={scrollNavData} single />

      <div className="page__img--dummy" id="home">
        <SectionWrapper
          className="page__header full min-height"
          title={stageName}
        />
        {typeof compatibleImage !== "string" ? (
          <div className="page__image">
            <PreviewCompatibleImage
              imageInfo={{
                image: compatibleImage,
                alt: `${stageName}`,
                imgStyle: {
                  objectPosition: `center ${imgYPosition ?? 50}%`,
                },
              }}
            />
          </div>
        ) : (
          <div
            className="page__image"
            style={{
              background: `url(${compatibleImage}) no-repeat`,
              backgroundSize: "cover",
              backgroundPosition: `center ${imgYPosition ?? 50}%`,
            }}
          />
        )}
      </div>

      <div className="page__wrapper" id="main">
        <header className="page__subtitle">{recapLine}</header>
        <PostContent content={content} className="page__wrapper__content" />
        <footer className="page__footer">
          <SocialIcons data={social} size={36} />
          {tags && tags.length ? (
            <div className="page__footer__tags">
              <span className="page__footer__tags--title">Tags</span>
              <ul className="page__footer__tags--list">
                {tags.map((tag) => (
                  <li key={tag + `tag`}>
                    <Link to={`/tags/${kebabCase(tag)}/`} className="link">
                      <span>{tag}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </footer>
        <Newsletter />
      </div>
    </div>
  );
};

ArtistPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object,
  compatibleImage: PropTypes.object,
  recapLine: PropTypes.string,
  stageName: PropTypes.string,
  social: PropTypes.object,
  imgYPosition: PropTypes.number,
};

const ArtistPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout ignoreNav>
      <ArtistPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        compatibleImage={post.frontmatter.featuredimage}
        helmet={
          <Helmet titleTemplate="REAL CVLTVRE | %s">
            <title>{`${post.frontmatter.stageName}`}</title>
            <meta
              property="og:title"
              content={`REAL CVLTVRE | ${post.frontmatter.stageName}`}
            />
            <meta
              name="description"
              content={`${post.frontmatter.stageName}: ${post.frontmatter.recapLine}`}
            />
            <meta
              name="og:description"
              content={`${post.frontmatter.stageName}: ${post.frontmatter.recapLine}`}
            />
            <meta
              property="og:image"
              content={post.frontmatter.featuredimage.childImageSharp.fluid.src}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        social={post.frontmatter.social}
        stageName={post.frontmatter.stageName}
        recapLine={post.frontmatter.recapLine}
        imgYPosition={post.frontmatter.imgYPosition}
      />
    </Layout>
  );
};

ArtistPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ArtistPage;

export const pageQuery = graphql`
  query ArtistPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        stageName
        recapLine
        tags
        social {
          links {
            type
            url
          }
        }
        imgYPosition
        featuredimage {
          childImageSharp {
            fluid(
              maxWidth: 1200
              srcSetBreakpoints: [250, 500, 800, 1200]
              quality: 100
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
